<template>
   <div :class="'m-p profile-container ' + ( this.$store.state.profileSideBar ? 'open' : '' )">
      <MyAccount />
   </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'My Profile',
components: {
   MyAccount: defineAsyncComponent( () => import('@/components/Profile/MyAccount.vue') ),
},
}
</script>